
import { defineComponent, onMounted, ref } from "vue";
import cardArticle from "@/components/card-article/card-article.vue"
import cardSelected from "@/components/card-selected/card-selected.vue"
import cardMessage from "@/components/card-message/card-message.vue"
import router from "@/router"
import { getArticle, getLeavingMsg } from "@/api"
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    cardArticle,
    cardSelected,
    cardMessage
  },
  setup() {
    const keyword = ref(null);
    const articles = ref(null);
    const notes = ref(null);
    const resources = ref(null);
    const leavingMsgs = ref(null);
    const loading = ref(true);

    // 搜索
    const onSearch = (value: string) => {
      if (!value) {
        return message.warning("请输入文章标题!");
      }
      if (value.length > 15) {
        return message.warning("你输入的文章标题太长!");
      }
      router.push(`search?keyword=${value}`);
    }

    // 获取文章参数
    const articleParams = {
      pagenum: 1,
      pagesize: 7
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 1,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      articleParams.pagenum = page;
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        loading.value = false;
        window.scrollTo(0, 0);
      })
    }
    // 路由跳转
    const goArticle = (id: string) => {
      router.push(`/article?id=${id}`)
    }

    onMounted(() => {
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
      }).catch(() => {
        loading.value = false;
      })
      getArticle({pagenum: 1, pagesize: 5, label: "随笔"}).then((res: any) => {
        notes.value = res.data.data.articles
      })
      getArticle({pagenum: 1, pagesize: 5, label: "资源"}).then((res: any) => {
        resources.value = res.data.data.articles
      })
      getLeavingMsg({pagenum: 1, pagesize: 3}).then((res: any) => {
        leavingMsgs.value = res.data.data.messages
      })
    })

    return {
      keyword,
      loading,
      onSearch,
      articles,
      leavingMsgs,
      notes,
      resources,
      articleParams,
      pagination,
      changePage,
      goArticle
    }
  }

})
