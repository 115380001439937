<template>
  <a-spin :spinning="loading" tip="加载中" :delay="500">
    <div id="home" class="center">
      <div class="top">
        <div class="banner">
          <a-carousel autoplay>
            <div class="img">
              <img src="@/assets/img/banner01.jpg" alt="">
            </div>
            <router-link class="img" to="/about">
              <img src="@/assets/img/banner02.png" alt="">
            </router-link>
            <router-link class="img" to="/tools/ciyun">
              <img src="@/assets/img/banner03.png" alt="">
            </router-link>
          </a-carousel>
        </div>
        <div class="right">
          <img src="@/assets/img/mxtian.png" alt="">
          <a-input-search
            v-model:value="keyword"
            placeholder="请输入文章标题进行搜索"
            style="width: 90%;margin:0 5%;"
            enter-button
            @search="onSearch"
          />
        </div>
      </div>
      <div class="main">
        <div class="left">
          <div class="selected">
            <div class="title-wrapper">
              <div class="title">精选</div>
            </div>
            <card-selected></card-selected>
          </div>
          <div class="message">
            <div class="title-wrapper">
              <div class="title">最新留言</div>
            </div>
            <ul>
              <li v-for="item in leavingMsgs" :key="item.id">
                <card-message :comment="item"></card-message>
              </li>
              <li>
                <router-link class="more-btn" to="/about">我要留言</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="middle">
          <div class="article">
            <ul>
              <li v-for="item in articles" :key="item.id">
                <card-article :article="item"></card-article>
              </li>
            </ul>
            <div style="text-align: center;">
              <a-pagination 
                v-model:current="pagination.current" 
                :total="pagination.total" 
                v-model:pageSize="pagination.pagesize"
                show-less-items 
                @change="changePage"
              />
            </div>
          </div>   
        </div>
        <div class="right">
          <div class="resources">
            <div class="title-wrapper">
              <div class="title">随笔</div>
            </div>
            <div class="essay">
              <a :href="`/article?id=${item.id}`" target="_blank" v-for="(item, index) in notes" :key="item.id">
                <img v-if="index == 0" :src="item.cover" alt="">
                <div class="title">{{ item.title }}</div>
                <div class="time">{{ item.label }} / {{ item.time }}</div>
              </a>
            </div>
          </div>
          <div class="resources">
            <div class="title-wrapper">
              <div class="title">资源</div>
            </div>
            <div class="essay">
              <a :href="`/article?id=${item.id}`" target="_blank" v-for="(item, index) in resources" :key="item.id">
                <img v-if="index == 0" :src="item.cover" alt="">
                <div class="title">{{ item.title }}</div>
                <div class="time">{{ item.label }} / {{ item.time }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import cardArticle from "@/components/card-article/card-article.vue"
import cardSelected from "@/components/card-selected/card-selected.vue"
import cardMessage from "@/components/card-message/card-message.vue"
import router from "@/router"
import { getArticle, getLeavingMsg } from "@/api"
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    cardArticle,
    cardSelected,
    cardMessage
  },
  setup() {
    const keyword = ref(null);
    const articles = ref(null);
    const notes = ref(null);
    const resources = ref(null);
    const leavingMsgs = ref(null);
    const loading = ref(true);

    // 搜索
    const onSearch = (value: string) => {
      if (!value) {
        return message.warning("请输入文章标题!");
      }
      if (value.length > 15) {
        return message.warning("你输入的文章标题太长!");
      }
      router.push(`search?keyword=${value}`);
    }

    // 获取文章参数
    const articleParams = {
      pagenum: 1,
      pagesize: 7
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 1,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      articleParams.pagenum = page;
      loading.value = true;
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        loading.value = false;
        window.scrollTo(0, 0);
      })
    }
    // 路由跳转
    const goArticle = (id: string) => {
      router.push(`/article?id=${id}`)
    }

    onMounted(() => {
      getArticle(articleParams).then((res: any) => {
        articles.value = res.data.data.articles
        pagination.value.total = res.data.data.total
        loading.value = false;
      }).catch(() => {
        loading.value = false;
      })
      getArticle({pagenum: 1, pagesize: 5, label: "随笔"}).then((res: any) => {
        notes.value = res.data.data.articles
      })
      getArticle({pagenum: 1, pagesize: 5, label: "资源"}).then((res: any) => {
        resources.value = res.data.data.articles
      })
      getLeavingMsg({pagenum: 1, pagesize: 3}).then((res: any) => {
        leavingMsgs.value = res.data.data.messages
      })
    })

    return {
      keyword,
      loading,
      onSearch,
      articles,
      leavingMsgs,
      notes,
      resources,
      articleParams,
      pagination,
      changePage,
      goArticle
    }
  }

})
</script>

<style lang="scss" scoped>
  #home {

    .top {
      height: 310px;
      display: flex;
      justify-content: space-between;
      
      .banner {
        width: 75%;
        height: 100%;
        border-radius: 6px;

        .img {
          
          img {
            width: 100%;
            height: 310px;
            cursor: pointer;
          }
        }
      }

      .right {
        width: 23%;
        height: 100%;
        background: #fff;
        border-radius: 6px;
        // box-shadow: 0 4px 8px 0 rgba(7, 17, 27, 0.1);

        img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 6px 6px 0 0;
          margin-bottom: 20px;
        }
      }
    }

    .main {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;  
      
      .left {
        width: 23%;
        .selected {
          padding: 20px;
          background: white;
          border-radius: 6px;
        }
        
        .message {
          @extend .selected;
          margin-top: 20px;

          ul {

            li {
              margin-bottom: 20px;
              padding-bottom: 20px;
              border-bottom: 1px dashed #eee;

              .more-btn {
                display: block;
                margin: 0 auto;
                line-height: 20px;
                text-align: center;
                width: 220px;
                padding: 10px 0;
                color: #fff;
                cursor: pointer;
                font-weight: 600;
                background: linear-gradient(270deg,#2254f4,#406dff);
                box-shadow: 0 12px 30px 0 rgba(34,84,244,.2);
                border-radius: 20px;
              }
            }
          }
        }
      }

      .middle {
        width: 50%;
        
        .article {

          ul {
            padding: 0;
            li {
              list-style: none;
              padding: 20px;
              background: white;
              border-radius: 6px;
              margin-bottom: 20px;
              border: 1px solid transparent;

              &:hover {
                border: 1px solid rgba(34, 84, 244,.5);
              }
            }
          }
        }
      }

      .right {
        width: 23%;

        .resources {
          background: white;
          border-radius: 6px;
          padding: 20px;
          margin-bottom: 20px;

          .essay {

            a {
              list-style: none;
              padding: 15px 0;
              border-bottom: 1px solid #eee;
              cursor: pointer;

              &:hover {
              
                .title {
                  color: #2254f4;
                }
              }

              img {
                display: block;
                width: 240px;
              }

              .title {
                color: #454545;
                margin: 10px 0px;
                font-size: 14px;
                font-weight: 600;
              }

              .time {
                font-size: 12px;
                color: #999;
              }
            }
          }
        }    
      }
    }

  }

  // 移动端
  @media screen and (max-width: 800px) {
    #home {

      .top {
        height: auto;
        display: block;
        justify-content: space-between;
        
        .banner {
          display: none;
        }

        .right {
          width: 100%;
          height: 100%;
          padding-bottom: 30px;

          img {
            height: 130px;
            object-fit: cover;
          }
        }
      }

      .main {
        flex-wrap: wrap;
        flex-direction: column-reverse;

        .left {
          width: 100%;
          margin-top: 20px;
        }

        .middle {
          width: 100%;
          
          .article {

            ul {
              padding: 0;
              
              li {
                border: 1px solid transparent;
              }
            }
          }
        }

        .right {
          display: none;
        }
      }
    }
  }
  
</style>