<template>
  <div id="card-selected">
    <a :href="`/article?id=${ranking[0].id}`" target="_blank" class="first">
      <div class="img">
        <img :src="ranking[0].cover" alt="">
      </div>
      <div class="title">{{ ranking[0].title }}</div>
      <div class="time">{{ ranking[0].label }} / {{ ranking[0].time }}</div>
    </a>
    <div class="other">
      <a :href="`/article?id=${item.id}`" target="_blank" v-for="(item, index) in ranking" :key="item.id">
        <div v-if="index != 0" class="img">
          <img :src="item.cover" alt="">
        </div>
        <div v-if="index != 0" class="right">
          <div class="title">{{ item.title }}</div>
          <div class="time">{{ item.label }} / {{ item.time }}</div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { getArticle } from "@/api"

export default defineComponent({
  setup() {
    const ranking = ref([0])
    onMounted(() => {
      getArticle({pagenum: 1, pagesize: 6, order:"browse_num"}).then((res: any) => {
        ranking.value = res.data.data.articles
      })
    })
    return {
      ranking
    }
  }
})
</script>

<style lang="scss" scoped>
  #card-selected {

    .title {
      font-size: 14px;
      margin: 10px 0px;
      color: #454545;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .first {
      cursor: pointer;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;

      &:hover {
              
        .title {
          color: #2254f4;
        }
      }

      .img {

        img {
          width: 240px;
          height: 150px;
          object-fit: cover;
        }
      }

      .time {
        font-size: 12px;
        color: #999;
      }
    }

    .other {

      a {
        cursor: pointer;
        list-style: none;
        display: flex;
        padding: 15px 0px;
        border-bottom: 1px solid #eee;
        
        &:hover {
            
          .title {
            color: #2254f4;
          }
        }

        .img {

          img {
            width: 80px;
            height: 60px;
          }
        }

        .right {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            margin: 0;
            font-size: 12px;
          }

          .time {
            color: #999;
            font-size: 12px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    #card-selected {

      .first {

        .img {

          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
</style>