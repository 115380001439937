
import { defineComponent, onMounted, ref } from "vue";
import { getArticle } from "@/api"

export default defineComponent({
  setup() {
    const ranking = ref([0])
    onMounted(() => {
      getArticle({pagenum: 1, pagesize: 6, order:"browse_num"}).then((res: any) => {
        ranking.value = res.data.data.articles
      })
    })
    return {
      ranking
    }
  }
})
